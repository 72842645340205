<template>
    <b-card no-body>
        <b-card-header>
            <h3>IFTA</h3>
        </b-card-header>
        <b-card-body>
            <b-overlay
                :show="show"
                rounded="sm"
                >
                <div>
                    <b-row class="d-flex align-items-center mb-2">
                        <b-col cols="1"></b-col>
                        <b-col cols="4">
                            <b-form-group label="Year">
                            <v-select
                                v-model="selectedYear"
                                :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                label="label"
                                :options="years"
                                @input="onChangeYear()"
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group label="Quarter">
                            <v-select
                                multiple
                                v-model="selectedQ"
                                :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                label="label"
                                :options="quarters"
                                @input="onChangeQuarter()"
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-button variant="primary" @click="exportPDF">
                                <feather-icon
                                    icon="DownloadIcon"
                                    class="mr-50"
                                />PDF</b-button
                            >
                            <b-button variant="info" @click="csvExport" class="ml-1">
                                <feather-icon
                                    icon="DownloadIcon"
                                    class="mr-50"
                                />CSV</b-button
                            >
                        </b-col>
                    </b-row>
                    <div>
                        <pdfexport ref="contentToExport" forcePageBreak=".page-break" :margin="'0.5cm'" :paper-size="'A4'" :keepTogether="'.prevent-split'">
                            <div v-if="items.length">
                                <ExportToPDF 
                                    :data="items"
                                    :dateText="dateText" 
                                />
                            </div>
                        </pdfexport>
                    </div>
                </div>
            </b-overlay>
        </b-card-body>       
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButtonGroup,
    BButton,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,
    BOverlay
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import { serverUri } from "@/config";
import ExportToPDF from "./ExportToPDF.vue";
import { PDFExport } from "@progress/kendo-vue-pdf";
import exportFromJSON from "export-from-json";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButtonGroup,
        BButton,
        BFormGroup,
        BOverlay,
        BTable,
        BFormSelect,
        BPagination,
        vSelect,
        ExportToPDF,
        pdfexport: PDFExport,

    },
    data() {
        return {
            show: false,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields : [
                {
                    key: "state",
                    label: "STATE",
                    sortable: true,
                },
                {
                    key: "miles",
                    label: "MILES",
                    sortable: true,
                },
                {
                    key: "gallons",
                    label: "GALLONS",
                    sortable: true,
                },
            ],
            filter: null,
            filterOn: [],
            years: [],
            quarters: [
                {
                    title: 1,
                    label: "Q1"
                },
                {
                    title: 2,
                    label: "Q2"
                },
                {
                    title: 3,
                    label: "Q3"
                },
                {
                    title: 4,
                    label: "Q4"
                }
            ],
            selectedYear: null,
            selectedQ: [],
            selectedQVal: [],
            items: [],
            dateText: "",
            totalMiles: 0,
            totalGallons: 0
        }
    },
    created() {
        this.getLast5years();
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getLast5years() {
            const currentYear = new Date().getFullYear(); 
            for (let i = 0; i < 5; i ++) {
                let year = { title: currentYear-i, label: currentYear-i};
                this.years.push(year);
            }
        },
        exportPDF: function() {
            this.$refs.contentToExport.save();
        },
        onChangeYear() {
            if (this.selectedYear && this.selectedQ.length)
                this.onload();
        },
        onChangeQuarter() {
            this.selectedQVal = [];
            this.selectedQ.map(item => {
                this.selectedQVal.push(item.title);
            });

            this.checkQ();
            
            if (this.selectedYear && this.selectedQ.length)
                this.onload();
        },
        onload() {
            this.totalMiles = 0;
            this.totalGallons = 0;
            this.items = [];
            this.show = true;
            axios
                .post(`${serverUri}/ifta/getifta`, {
                    year: this.selectedYear.title,
                    quarters: this.selectedQVal
                })
                .then((res) => {
                    const map = new Map();
                    res.data.qty.forEach(item => map.set(item.state, item));
                    
                    // create new object by state
                    var result = res.data.miles.reduce((accObj, currentObj) => {
                        accObj[currentObj.state] = accObj[currentObj.state] || [];
                        accObj[currentObj.state].push(currentObj);
                        return accObj;
                    }, {}); //{} is the accumulator object

                    var temp = [];
                    for (let i = 0; i < Object.keys(result).length; i ++) {
                        var key = Object.keys(result)[i];
                        
                        // create new object by equipment_id(tractor)
                        var subRes = result[key].reduce((accObj, currentObj) => {
                            accObj[currentObj.equipment_id] = accObj[currentObj.equipment_id] || [];
                            accObj[currentObj.equipment_id].push(currentObj);
                            return accObj;
                        }, {});

                        var miles = 0;

                        for (let j = 0; j < Object.keys(subRes).length; j++) {
                            var subKey = Object.keys(subRes)[j];

                            for (let k = 1; k < subRes[subKey].length; k ++) {
                                miles += (subRes[subKey][k].odometer - subRes[subKey][k-1].odometer);
                            }
                        }

                        temp.push({ state: key, miles: miles})
                    }

                    temp.forEach(item => map.set(item.state, {...map.get(item.state), ...item}));
                    const mergeRes = Array.from(map.values());

                    this.items = mergeRes.map(item => {
                        return {
                            ...item,
                            miles: item.miles ? Number(item.miles.toFixed(1)).toLocaleString() : 0,
                            gallons: item.gallons ? Number(item.gallons.toFixed(1)).toLocaleString(): 0
                        }
                    })

                    mergeRes.map(item => {
                        this.totalMiles += item.miles ? item.miles : 0;
                        this.totalGallons += item.gallons ? item.gallons: 0;
                    })

                    this.totalMiles = Number(this.totalMiles.toFixed(1)).toLocaleString();
                    this.totalGallons = Number(this.totalGallons.toFixed(1)).toLocaleString();

                    this.items.push({ state: "Total", gallons: this.totalGallons, miles: this.totalMiles })
                    this.show = false;
                })
        },
        checkQ() {
            let q1 = this.selectedQVal.indexOf(1);
            let q2 = this.selectedQVal.indexOf(2);
            let q3 = this.selectedQVal.indexOf(3);
            let q4 = this.selectedQVal.indexOf(4);

            if (q1 != -1 && q2 == -1 && q3 == -1 & q4 == -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "03-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 != -1 && q3 == -1 & q4 == -1) {
                this.dateText = "04-01-" + this.selectedYear.title + " - " + "06-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 == -1 && q3 != -1 & q4 == -1) {
                this.dateText = "07-01-" + this.selectedYear.title + " - " + "09-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 == -1 && q3 == -1 & q4 != -1) {
                this.dateText = "10-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 != -1 && q3 == -1 & q4 == -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "06-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 == -1 && q3 != -1 & q4 == -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "03-31-" + this.selectedYear.title
                + " , " + "07-01-" + this.selectedYear.title + " - " + "09-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 == -1 && q3 == -1 & q4 != -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "03-31-" + this.selectedYear.title
                + " , " + "10-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 != -1 && q3 != -1 & q4 == -1) {
                this.dateText = "04-01-" + this.selectedYear.title + " - " + "09-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 != -1 && q3 == -1 & q4 != -1) {
                this.dateText = "03-01-" + this.selectedYear.title + " - " + "06-31-" + this.selectedYear.title
                + " , " + "10-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 == -1 && q3 != -1 & q4 != -1) {
                this.dateText = "07-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 != -1 && q3 != -1 & q4 == -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "09-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 != -1 && q3 == -1 & q4 != -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "06-31-" + this.selectedYear.title
                + " , " + "10-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 == -1 && q2 != -1 && q3 != -1 & q4 != -1) {
                this.dateText = "04-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 == -1 && q3 != -1 & q4 != -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "03-31-" + this.selectedYear.title
                + " , " + "06-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            } else if (q1 != -1 && q2 != -1 && q3 != -1 & q4 != -1) {
                this.dateText = "01-01-" + this.selectedYear.title + " - " + "12-31-" + this.selectedYear.title;
            }

        },
        csvExport() {
            const data = this.items;
            const fileName = "IFTA";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({ data, fileName, exportType });
        }
    }
}
</script>
