<template>
    <div>
        <div>
            <h4>State Fuel Miles Report</h4>
            <span>Report Period: {{this.dateText}}</span><br>
            <span>Company: Ground Force Trucking Inc.</span>
        </div>
            <table>
                <tr>
                    <th>State</th>
                    <th>Miles</th>
                    <th>Gallons</th>
                </tr>
                <tr v-for="item in data" :key="item.state">
                    <td>{{ item.state }}</td>
                    <td>{{ item.miles }}</td>
                    <td>{{ item.gallons }}</td>
                </tr>
            </table>
    </div>
</template>

<script>
    
export default {
    props: {
        data: Array,
        dateText: String
    },
}

</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 1px;
  border: 1px solid #ddd;
}

th {
  background-color: #4472c4;
  color: white;
}

h5 {
    text-align: center;
}

span {
    font-weight: bold;
}
</style>